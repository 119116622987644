// Global dependencies
import App from './app';
import tilt from '../js/vendor/tilt';
/**
  Display message on the console if activateConsole is true.

  Parameters :
  - active : boolean, display the message if it is true.
  - message : string, the message to display.

 - Remark: App.activateConsole is defined in app.js.
  You can disable all the display if you turn activateConsole to false.
 */

function displayMessage(active, message) {
  if (active) {
    console.log(message);
  }
}

/**
  Display the version of javascript used in the application on the console.

 - Remark: App.version is defined in app.js

 */

function logAppVersion() {
  displayMessage(App.activateConsole, "JS initialized in version: " + App.version);
}

/**
 Set Active function

 - Adds the active class to the current page link.

 */
function setActive() {
  $('a').each(function() {
    if ($(this).prop('href') == window.location.href) {
      $(this).addClass('active');
    }
  });
}

/**
 Scroll fluid function.

 - Returns: false.

 - Remark:
    'var page' is the target of the scroll, found after the attribute 'href'.
    'var speed' is the speed of the scroll.

 - Preconditions:
    add the class js-scrollTo to the object you want to click on to launch the scroll,
    add the attribute href to the object to define the target
    add an id to the target.
 */
function scrollTo() {
	$('.js-scrollTo').on('click', function() { // Au clic sur un élément
		var page = $(this).attr('href'); // Page cible
		var speed = 750; // Durée de l'animation (en ms)
    if ($(page).size() == 0) {
      displayMessage(App.activateConsole, "Error, the size of the page is equal to 0");
      return false;
    }
		$('html, body').animate( { scrollTop: $(page).offset().top }, speed ); // Go
    return false;
	});
}

/**
 Touch end function

 - The touchend event will redirect the browser to the target of the link as soon as the tap finishes.

 */
function touchend() {
  $(".nav-link").on("click touchend", function(e) {
    var el = $(this);
    var link = el.attr("href");
    window.location = link;
  });
}



/**
 Scroll floating menu

 - Source : https://codepen.io/jaehee/pen/zrEQMP?depth=everything&limit=all&order=popularity&page=2&q=scroll+menu&show_forks=false
 - Adds the "on" class to the section when the user is in the correspondant section of the page.

 */
(function (global, $) {

    var $menu     = $('.floating-menu li.m'),
        $contents = $('.scroll'),
        $doc      = $('html, body');

    $(window).scroll(function(){

        var scltop = $(window).scrollTop();

        $.each($contents, function(idx, item){
            var $target   = $contents.eq(idx),
                i         = $target.index(),
                targetTop = $target.offset().top;

            if (targetTop <= scltop) {
                $menu.removeClass('on');
                $menu.eq(idx).addClass('on');
            }
            if (!(200 <= scltop)) {
                $menu.removeClass('on');
            }
        });

    });

    var btnTop = $('.btn-top');
    btnTop.on('click','a', function(e){
        e.preventDefault();
        $doc.stop()
                .animate({
                    scrollTop : 0
                },800)
    });

}(window, window.jQuery));



/**
 Parallax Scrolling

 - Source : https://codepen.io/taniarascia/pen/mPpZZM?limit=all&page=2&q=parallax

 */
var parallaxElements = $('.parallax-images'),
    parallaxQuantity = parallaxElements.length;

$(window).on('scroll', function () {

  window.requestAnimationFrame(function () {

    for (var i = 0; i < parallaxQuantity; i++) {
      var currentElement =  parallaxElements.eq(i);
      var scrolled = $(window).scrollTop();

        currentElement.css({
          'transform': 'translate3d(0,' + scrolled * -0.2 + 'px, 0)'
        });

    }
  });

});

/**
 Carousel swiping

 - Allows the user to swipe for the carousel flux in Homepage.

 */
function swipe() {
  $(".carousel").swipe({

    swipe: function(event, direction, distance, duration, fingerCount, fingerData) {

      if (direction == 'left') $(this).carousel('next');
      if (direction == 'right') $(this).carousel('prev');

    },
    allowPageScroll:"vertical"

  });
}


/**
 Homepage Team Carousel

 - Source : http://kenwheeler.github.io/slick/

 - "center" is the class of the carousel in the Team section in the Homepage. Check the website for all the available options.

 */
 function carousel() {
   $('.center').slick({
     arrows: false,
     centerMode: true,
     centerPadding: '60px',
     slidesToShow: 3,
     autoplay: true,
     autoplaySpeed: 4000,
     focusOnSelect: true,
     variableWidth: true,
     swipe: false,
     accessibility: true,
     responsive: [
       {
         breakpoint: 768,
         settings: {
           centerPadding: '40px',
           slidesToShow: 3,
           touchThreshold: 100,
           swipe: true
         }
       },
       {
         breakpoint: 480,
         settings: {
           centerPadding: '40px',
           slidesToShow: 3,
           swipe: true,
           touchThreshold: 100
         }
       }
     ]
    });
}


/**
 Scroll Off

 - Source : https://github.com/kylelam/kylelam.github.io/blob/master/iframe.html

 */
function scrollOff() {
  $('#map').addClass('scrolloff');

  $('#overlay').on("mouseup",function(){
      $('#map').addClass('scrolloff');
  });
  $('#overlay').on("mousedown",function(){
      $('#map').removeClass('scrolloff');
  });
  $("#map").mouseleave(function () {
      $('#map').addClass('scrolloff');
  });
}

/**
 PreventScrollTop

 - Prevents from scrolling top in Chrome

 */

function preventScrollTop() {
  var hash = location.hash;
  location.hash = '';
  location.hash = hash;
}


/**
ShareMenuTransition

- Calculate the transition delay of each child.
*/

function shareMenuTransition() {
  var nodes = document.getElementById('share-menu').getElementsByTagName('li');
  for(var i=0; i<nodes.length; i++) {
      var delay = 0.2 - i * 0.04;
      nodes[i].style.transitionDelay = delay + "s";
  }
}

/**
ShareButton

- Display share links on button click.
*/

function shareButton() {
  $('button.share').on("click",function(){
      $('.networks').css("display", "block");
  });
}

/**
navbarScroll

- Make navbar disappear when scrolling down and appearing when scrolling up
*/

function navbarScroll() {
  var width = (window.innerWidth > 0) ? window.innerWidth : document.documentElement.clientWidth;
  if(width >= 768){
    var previousScroll = 0,
      headerOrgOffset = $('.header-id').height();

      $('.header-wrap').height($('.header-id').height());

      $(window).scroll(function () {
          var currentScroll = $(this).scrollTop();
          if (currentScroll > headerOrgOffset) {
              if (currentScroll > previousScroll) {
                  if( jQuery(this).scrollTop() > 400 ) {
                    $('.header-wrap').slideUp();
                  }
              } else if( jQuery(this).scrollTop() < 600 ) {
                    $('.header-wrap').slideDown();
              } else {
                $('.header-wrap').slideDown();
              }
          }
          previousScroll = currentScroll;
      });
    }
    else {
      $('.header-wrap').height(0);
    }
  }

$(document).ready(function() {
  displayMessage(App.activateConsole, "Enter main.js");
  window.onresize = navbarScroll;
  navbarScroll();
  preventScrollTop();
  logAppVersion();
  scrollTo();
  touchend();
  setActive();
  carousel();
  swipe();
  scrollOff();
  shareMenuTransition();
  shareButton();
  displayMessage(App.activateConsole, "Init JS functions");
  });
