// Global dependencies
var $ = require('jquery');

window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js').default;

require('bootstrap');
require('slick-carousel');
require('ev-emitter');
require('imagesloaded');
require('animejs');

export default (function(){
	'use strict';

	// Save a reference to the global object
	var root = window;
	var App = root.App = window.App || {};

	App.$ = $;

	App.activateConsole = true;

	// Versioning
	App.version = "0.0.1";

	return App;

}).call(this);
